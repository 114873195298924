<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar
    v-model="isSidebarActiveLocal"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    variant="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
  >
    <div class="mt-6 flex items-center justify-between pl-6">
      <h4>{{ data.position }} Applications</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isSidebarActiveLocal = false"
      />
    </div>
    <hr>

    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <b-modal
        v-model="popupApplicant"
        centered
        hide-footer
        title="Applicant Information"
      >
        <b-card>
          <b-button
            class="mt-4 mb-4 w-full shadow-lg"
            variant="primary"
            @click="downloadResume(applicant_info.email)"
          >Download Resume</b-button>
          <hr>
          <hr>
          <h4 class="mt-5">
            Personal Information:
          </h4>
          <hr>
          <div class="flex justify-between mt-1">
            <h6>Full Name: {{ applicant_info.fullname }}</h6>
          </div>
          <hr>

          <div class="d-flex justify-content-between mt-1">
            <h6>Date of Birth: {{ applicant_info.date_of_birth }}</h6>
          </div>
          <hr>

          <div class="flex justify-between mt-1">
            <h6>Email Address: {{ applicant_info.email_address }}</h6>
          </div>
          <hr>

          <div
            v-if="applicant_info.phone_number != 0"
            class="flex justify-between"
          >
            <h6>Contact: {{ applicant_info.phone_number }}</h6>
          </div>
          <hr v-if="applicant_info.phone_number != 0">
          <div class="d-flex justify-content-between mt-1">
            <h6>Highest Qualification: {{ applicant_info.highest_qualification }}</h6>
          </div>
          <hr>
        </b-card>
      </b-modal>
      <div
        v-for="(item, index) in data.applicants"
        :key="index"
        class="px-3 mt-4"
      >
        <div class="flex justify-between">
          <div class="vx-col mt-3">
            <p>{{ item.fullname }}</p>
          </div>
          <!-- <div class="vx-col">
            <feather-icon
                :icon="[item.icon]"
                class="p-3 inline-flex rounded-full mb-1 mx-2 text-warning"
                size="large"
                style="background: rgba(var(--vs-warning),.15)"
              ></feather-icon>
          </div> -->
          <div class="vx-col">
            <b-button
              variant="primary"
              @click="more_info(item)"
            >More Info</b-button>
          </div>
        </div>
        <hr class="">
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataCategory: null,
      dataImg: null,
      dataOrder_status: 'pending',
      dataPrice: 0,
      popupApplicant: false,
      applicant_info: {},
      category_choices: [
        { text: 'Audio', value: 'audio' },
        { text: 'Computers', value: 'computers' },
        { text: 'Fitness', value: 'fitness' },
        { text: 'Appliance', value: 'appliance' },
      ],

      order_status_choices: [
        { text: 'Pending', value: 'pending' },
        { text: 'Canceled', value: 'canceled' },
        { text: 'Delivered', value: 'delivered' },
        { text: 'On Hold', value: 'on_hold' },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
        && this.dataName
        && this.dataCategory
        && this.dataPrice > 0
      )
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        const {
          category, id, img, name, order_status, price,
        } = JSON.parse(
          JSON.stringify(this.data),
        )
        this.dataId = id
        this.dataCategory = category
        this.dataImg = img
        this.dataName = name
        this.dataOrder_status = order_status
        this.dataPrice = price
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  methods: {
    more_info(details) {
      this.applicant_info = details
      this.popupApplicant = true
    },
    downloadResume(email) {
      this.$http
        .get(`/api/download-resume/${email}`)
        .then(response => {
          if (response.data.errors) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Download Notification',
                  icon: 'AlertCircleIcon',
                  text: "Applicant hasn't uploaded their resume yet.",
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            const header = response.headers
            const file_type = header['content-type'].split('/').pop()

            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: header['content-type'] }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${email}#.${file_type}`) // or any other extension
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch(error => {})
    },
    initValues() {
      if (this.data.id) return
      this.dataId = null
      this.dataName = ''
      this.dataCategory = null
      this.dataOrder_status = 'pending'
      this.dataPrice = 0
      this.dataImg = null
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            id: this.dataId,
            name: this.dataName,
            img: this.dataImg,
            category: this.dataCategory,
            order_status: this.dataOrder_status,
            price: this.dataPrice,
          }

          if (this.dataId !== null && this.dataId >= 0) {
            this.$store.dispatch('dataList/updateItem', obj).catch(err => {
              console.error(err)
            })
          } else {
            delete obj.id
            obj.popularity = 0
            this.$store.dispatch('dataList/addItem', obj).catch(err => {
              console.error(err)
            })
          }

          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
